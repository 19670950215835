import { Link } from "gatsby"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Card, CardHeader } from "@material-ui/core"

const useStyles = makeStyles({
  card: {
    flexGrow: 1,
  },
  link: {
    color: "#912800",
    textDecoration: "none",
    fontWeight: "300",
    "&:hover": {
      color: "#555",
    },
  },
})

export default function BlogPost(props) {
  const classes = useStyles()
  return (
    <Card className={classes.card} elevation={0}>
      <CardHeader
        title={
          <Link to={props.path} className={classes.link}>
            {props.title}
          </Link>
        }
        titleTypographyProps={{ variant: "h4" }}
      />
    </Card>
  )
}
