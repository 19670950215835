import React from "react"
import { graphql } from "gatsby"
import BlogPost from "../components/blogpost"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Typography } from "@material-ui/core"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const posts = data.allMarkdownRemark.edges
    return (
      <Layout>
        <SEO title="All posts" />
        <Typography
          variant="h3"
          style={{ marginBottom: "50px", fontWeight: "300" }}
        >
          These are my{" "}
          <span style={{ color: "#912800", fontSize: "4rem" }} class="garamond">
            thoughts
          </span>
          .
        </Typography>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title
          return (
            <div key={node.id}>
              <BlogPost path={node.fields.slug} title={title} />
            </div>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
      edges {
        node {
          id
          fields {
            slug
          }
          internal {
            content
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
          }
        }
      }
    }
  }
`
